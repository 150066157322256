<template>
    <header class="py-3 w-full justify-between flex items-center sticky top-0">
        <WhatsDay />
        <div class="flex gap-10">
            <SearchInput />
            <div class="flex gap-5 items-center text-2xl">
                <i class="icon-full-screen cursor-pointer" @click="fullScreen()"></i>
                
                <el-popover
                :width="300"
                popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                >
                <template #reference>
                    <div class="relative">
                        <i class="icon-notification cursor-pointer"></i>
                        <sup class="text-sm absolute -top-2 -right-2 bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white font-medium ">{{ notifyList.length }}</sup>
                    </div>
                </template>
                <template #default>
                    <div
                    class="demo-rich-conent"
                    style="display: flex; gap: 16px; flex-direction: column" v-if="notifyList.length>0"
                    >
                        <div v-for="event in notifyList" :key="event">
                            <p v-if="event.type==1">{{event.user_data.name}} izinli
                                <sup v-if="event.leave_type==1">Yarım Gün - {{ event.half_type==0 ? 'Öğleden Önce' : 'Öğleden Sonra' }}</sup>
                                <sup v-if="event.leave_type==2">Saatlik - {{ event.hours }} Saat</sup>
                            </p>
                            <p v-if="event.type==2">{{event.user_name}} raporlu</p>
                            <p v-if="event.type==3">{{event.user_name}} uçuşta</p>
                            <p v-if="event.type==4">{{event.user_name}} doğum günü!</p>
                        </div>
                    </div>
                    <div v-else>İzin yok</div>
                </template>
                </el-popover>
                <i class="icon-user cursor-pointer"></i>
                <router-link to='/settings'>
                    <i class="icon-setting cursor-pointer"></i>
                </router-link>
            </div>
            <UserProfile />
        </div>
    </header>
</template>
<script>
import WhatsDay from "./WhatsDay";
import UserProfile from './UserProfile';
import SearchInput from './SearchInput.vue';
import { ElPopover } from "element-plus";
export default {
    name:'HeaderFixed',
    components: {
        WhatsDay,
        UserProfile,
        SearchInput,
        ElPopover
    },
    data() {
        return {
            notifyList: []
        }
    },
    methods: {
        getCalendar() {
            const date = this.baseDate(new Date())
            try {
                this.$axios.get(`/calendar?f=start|${date}|contain,type|1|equal`).then((response) => {
                    // Verileri notifyList'e atayın
                    this.notifyList = response.data.data;
                }).catch((error) => {
                    console.error('Takvim verilerini alırken hata oluştu:', error);
                });
            } catch (error) {
                console.error('Takvim verilerini alırken hata oluştu:', error);
            }
        },
    },
    created () {
        this.getCalendar();
    },
}
</script>
<style lang="">
    
</style>