import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from '../axios.js';
import router from '@/router/index.js';


const store = createStore({
  state: {
    isLogin: false,
    user: null,
    authToken: null,
    loginError:null,
    cacheDepartman:{},
  },
  mutations: {
    login(state, userResponse) {
      state.isLogin = true;
      state.user = userResponse.data;
      state.authToken = userResponse.token
    },
    logout(state) {
      state.isLogin = false;
      state.user = null;
      state.authToken = null,
      state.cacheDepartman={}
      router.push('/login')
    },
    loginError(state, error) {
      state.loginError = error;
    },
    SET_CACHE_DEPARTMAN(state, job=undefined){
      if(job==undefined){
        state.cacheDepartman={} //departman sayfasında obje tamamen temizleniyor.
      }else if(job.name!=undefined){
        state.cacheDepartman[job.id] = {} //departman id ile boş obje oluşturuldu
      }else{
        state.cacheDepartman[job.departman_id][job.id]= job.data //departman altına iş tanım id si ile data cachelenecek
      }
    },
    SET_SETTINGS(state, data) {
      const index = state.settings.findIndex(item => item.id === data.id);
      if (index !== -1) {
        // Eğer bulunduysa, güncelle
        state.settings[index] = data;
      } else {
        // Bulunamadıysa, yeni öğe olarak ekle
        state.settings.push(data);
      }
    }
  },
  actions: {
    async login({ commit }, user) {
      try {
        const response = await axios.post(`auth/login`, user);
        if(response.data.success){
          commit('login', response.data);
          commit('loginError', null);
        }else{
          commit('loginError', response.data.message.text);
        }
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    },
    async getSetting(_,id) { //commit yerine _ yazılıyor
      try {
        const response = await axios.get(`settings/${id}`);
        return response.data.data[0];
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateSetting({ commit },data) {
      await axios.put(`settings/${data.id}`,data)
      .then(response => {
        let settings = response.data.data;
        commit('SET_SETTINGS', settings);
      })
      .catch(error => {
        console.error(error);
      });
    },
    logout({ commit }) {
      commit('logout');
    },
    async setCacheDepartman({ commit }, job) {
      commit('SET_CACHE_DEPARTMAN', job);
    },
  },
  getters: {
    isLogin: state => state.isLogin,
    user: state => state.user,
    authToken: state => state.authToken,
    loginError: state => state.loginError,
    authority: state => state.user ? state.user.authority : null,
    userId: state => state.user ? state.user.id : null
  },
  plugins: [createPersistedState()]
});

export default store;