<template>
    <div>
      <img :width="size" :style="imgStyle" :src="resolvedAvatar" :class="{'rounded-full':rounded}">
    </div>
  </template>
  
  <script>
  export default {
    props: {
      size: {
        type: Number,
        default: 100
      },
      avatar: {
        type: String
      },
      rounded: {
        type : Boolean,
        default : true
      }
    },
    computed: {
      resolvedAvatar() {
        return this.avatar ? process.env.VUE_APP_API_URL+this.avatar : require('@/assets/img/avatar.png');
      },
      imgStyle() {
            let styles = this.size ? { height: `${this.size}px` } : {};
            if (this.rounded) {
                styles.borderRadius = '50%';
            }
            return styles;
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  