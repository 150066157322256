<template>
    <aside class="h-full border-r-2 transition-all ease-in-out w-14" :class="sideIsOpen ? 'sideIsOpen' : 'sideIsClose justify-center'">
        <div class="flex justify-between px-2 h-14 items-center mt-3 w-full mb-7">
            <img src="@/assets/logo.png">
            <i class="icon-menu text-black fs text-3xl cursor-pointer" @click="toggleSideBar"></i>
        </div>
        <div class="text-left">
            <ul class="grid">
                <li v-for="item in filteredMenus" :key="item">
                    <router-link @click.prevent="menuShow(item)" :to="item.link ? item.link : ''" class="flex gap-4 items-center py-4 px-3 transition hover:bg-sky-700 hover:text-white" :key="item.name">
                        <div class="flex gap-4 items-center">
                            <i class="text-xl" :class="'icon-' + item.icon"></i>
                            <p class="sideHidden font-medium">{{ item.name }}</p>
                        </div>
                        <div v-if="item.children" class="ml-auto sideHidden text-sm" :class="{'rotate-180 mt-[-4px]':item.show}">
                            <i class="icon-down-chevron"></i>
                        </div>
                    </router-link>
                    <!-- Alt Menü Gösterimi -->
                    <ul id="dropdown-example" v-if="item.children && item.show">
                        <li v-for="child in item.children" :key="child">
                            <router-link :to="child.link" class="flex gap-2 items-center py-2 px-3 pl-5 transition hover:bg-sky-700 hover:text-white">
                                <div class="flex gap-4 items-center">
                                    <i class="text-xl" :class="'icon-' + child.icon"></i>
                                    <p class="sideHidden">{{ child.name }}</p>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <div 
                    @click="logoutClick"
                    class="flex gap-4 items-center py-4 px-3 transition hover:bg-sky-700 hover:text-white cursor-pointer">
                        <div class="flex gap-4 items-center">
                            <i class="text-xl icon-logout"></i>
                            <p class="sideHidden font-medium">Çıkış</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
export default {
    name: 'SideBar',
    components: {
    },
    data(){
        return{
            menus:[
                {name:"Anasayfa", icon:'home',link:'/',requiredAuthority: [0, 1]},
                {name:"Postalar", icon:'envelope',link:'/inbox',requiredAuthority: [0, 1]},
                {name:"Demirbaşlar", icon:'pie-graph-split',link:'/fixtures', requiredAuthority: [0]},
                {name:"Çalışan", icon:'support', requiredAuthority: [0],
                    children:[
                        {name:"Çalışan Listesi", icon:'user-2',link:'/users'},
                        {name:"İzinler", icon:'to-do-list',link:'/leaves'},
                        {name:"Multinet", icon:'work-process',link:'/multinet'},
                        {name:"Avans", icon:'time',link:'/payments'},
                        {name:"Kesinti", icon:'time',link:'/payments?type=2'},
                ]},
                {name:"Takvim", icon:'calendar',link:'/calendar',requiredAuthority: [0,1]},
                {name:"Müşteriler", icon:'briefcase',link:'/customers',requiredAuthority: [0]},
                {name:"Departman ve İş Tanımları", icon:'departman',link:'/departmans',requiredAuthority: [0]},
                {name:"Ayarlar", icon:'setting',link:'/settings',requiredAuthority: [0]},
                {name:"Profile", icon:'profile',link:'/profile',requiredAuthority: [1]},
                {name:"İzinlerim", icon:'profile-leaves,',link:'/profile-leaves',requiredAuthority: [1]},
                {name:"Multinet Ödemelerim", icon:'profile-multinet,',link:'/profile-multinet',requiredAuthority: [1]},
                {name:"Demirbaşlar", icon:'pie-graph-split',link:'/profile-fixtures',requiredAuthority: [1]},
                {name:"Avanslar ve Kesintiler", icon:'profile-payments,',link:'/profile-payments',requiredAuthority: [1]}
            ],
            sideIsOpen:localStorage.getItem('sideIsOpen')=='true' ? true : false
        }
    },
    methods: {
        logoutClick(){
            this.$store.dispatch('logout');
            this.$router.push('/login');
        },
        toggleSideBar() {
            this.sideIsOpen=!this.sideIsOpen
            localStorage.setItem('sideIsOpen',this.sideIsOpen);
        },
        menuShow(item){
            if (item.children && !item.children.length) {
                this.fetchCustomersList().then(customers => {
                    item.children = customers;
                    item.show = !item.show;
                });
            } else {
                item.show = !item.show;
            }
        },
    },
    computed: {
        filteredMenus() {
            const userAuthority = this.$store.getters.authority; // Kullanıcının yetki seviyesini alın

            return this.menus.filter(menu => {
                return menu.requiredAuthority.includes(userAuthority)
            });

        }
    }
}
</script>

<style>
.sideIsOpen{
    width: 280px !important;
}
.sideIsClose .sideHidden{
    display: none;
}
</style>