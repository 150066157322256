<template>
  <div class="flex h-full text-theme">
    <el-container>
      <SideBar v-if="$store.getters.isLogin"/>
      <el-container>
        <el-header class="!h-auto" v-if="$store.getters.isLogin">
          <HeaderFixed />
        </el-header>
        <el-main class="!pt-0">
          <el-container v-if="!loading">
            <div class="flex items-center justify-center w-full h-full">
              <button type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Yükleniyor...
              </button>
            </div>
          </el-container>
          <router-view class="overflow-y-auto h-full rounded-md p-4 text-mainColor" :class="{'bg-white':$route.meta.requiresAuth}" v-else/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import SideBar from '@/components/SideBar.vue';
import HeaderFixed from '@/components/header/HeaderFixed.vue';
import { ElContainer, ElHeader, ElMain } from 'element-plus';

export default {
  components: {
    SideBar,
    HeaderFixed,
    ElContainer,
    ElHeader,
    ElMain
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
  },
  created () {
    this.$router.beforeEach((to, from, next) => {
      this.loading = false; // Sayfa yüklenirken loading göstergesini göster
      next();
    });

    this.$router.afterEach(() => {
      this.loading = true; // Sayfa yüklendikten sonra loading göstergesini gizle
    });
  },
};
</script>
<style>
body, html{
  height: 100vh;
}
#app {
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f4;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
