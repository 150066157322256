<template>
    <div class="flex items-center relative w-[261px]">
        <input type="text" class="h-full rounded-full w-full indent-4 focus:outline-0 shadow-inner bg-[#DBDFDF]" placeholder="Ara">
        <i class="icon-search absolute top-1/2 -translate-y-1/2 right-4 text-xl cursor-pointer"></i>
    </div>
</template>

<script>
    export default {
        name:'SearchInput'
    }
</script>

<style lang="scss" scoped>

</style>