export default {
    data() {
      return {
        isMobile: window.innerWidth < 768,        
        ElDatePickerFormat:'YYYY-MM-DD',
        months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        userId:this.$store.getters.userId,
        authority:this.$store.getters.authority
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      
      convertUtcToLocal(utcDateStr) {
        const date = new Date(utcDateStr);
      
        // Yerel saat dilimine dönüştür
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arasında olduğundan +1 ekliyoruz
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      customFormat(val) {
        const months = [
          'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
          'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
        ];
        const date = new Date(val);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      },   
      isNumber(val){
        return !isNaN(parseFloat(val)) && isFinite(val)
      },
      beforeDay(date){
        var time = new Date(date)
        return time.setDate(time.getDate() - 1)
      },
      baseDate(date){
        if (date instanceof Date) {
            // Tarihi YYYY-MM-DD formatına dönüştür
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekliyoruz
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        return date;
      },
      afterDay(date){
        var time = new Date(date)
        return time.setDate(time.getDate() + 1)
      },
      calculateDateDifference(startDate, endDate) {
        let timeDifference = endDate.getTime() - startDate.getTime();
    
        return {
          days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
        };
      },
      eventTitle(event,name){
        let eventType=this.$globalVeriable.eventTypeList.find(item=>item.type==event.type && item.leave_type==event.leave_type)
        const icon = event.half_type == 1 ? 'half-before' : eventType.icon
        let title = ''
        if(name){
          title+=event.user_data.name
          title+='- '
        }

        if(event.leave_type !=2){
          if(event.leave_type == 0 && (event.type == 1 || event.type == 2) ){
            title+=event.number_of_days+' Gün '
          }
          if(event.type!=1 || event.leave_type!=0){
            title+=eventType.name
          }
        }else{
          title+=event.hours+' Saat'
        }

        return {
          icon:icon,
          title:title,
          color:eventType.color
        }
      },
      dateDiff(start,end,type){
        let dayCount = 0;
        let currentDate = new Date(start);

        while (currentDate < new Date(end)) {
          const dayOfWeek = currentDate.getDay();

          // Hafta sonu (Cumartesi veya Pazar) değilse tarih aralığına ekle
          if ((dayOfWeek !== 0 && dayOfWeek !== 6) || type==2) {
            dayCount++
          }

          // Bir gün ekleyerek devam et
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dayCount;
      },
      fullScreen() {
        if (!document.fullscreenElement) {
            if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
            document.exitFullscreen();
            }
        }
      },

      //burada formvalidator rules'ları yer alıyor
      rulesReq(fieldName) {
        return { required: true, message: `Lütfen ${fieldName} giriniz` };
      },
      rulesNum() {
        return [
          { type: 'number', message: 'sayısal veri zorunlu' },
        ];
      },
      rulesTc() {
        return [
          { validator: (rule, value) => {
              const regex = /^[0-9]+$/;
              if (!value.match(regex)) {
                  return Promise.reject('Sadece numara');
              }
              return Promise.resolve();
          }, trigger: 'blur' },
          { min: 11, max: 11, message: '11 haneli olmalıdır' }
        ];
      },
      rulesMail() {
        return { type: 'email', message: 'Geçerli mail adresi giriniz', trigger: ['blur', 'change'] }        ;
      },
      rulesPhone() {
        return { min: 18, max: 18, message: 'Geçerli numara giriniz' };
      },
      rulesPass(){
        return { min: 6, message: 'Minimum 6 karakter olmalıdır', trigger: 'blur' }
      }

    },
    computed: {
      departmanClass(){
          return {
              1: 'bg-[#7691DA] text-white',
              2: 'bg-[#d7bf6e] text-white',
              3: 'bg-[#c94292] text-white',
              4: 'bg-[#04ABB6] text-white',
              5: 'bg-[#E6A6DB] text-white',
              6: 'bg-[#FF5B5C] text-white',
          }
      },
      isAdmin() {
          return this.authority == 0;
      }
      
  },
  };
  