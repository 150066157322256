<template>
    <div class="flex items-center">
        <div class="text-right mr-2">
            <div>{{ $store.getters.user.name}}</div>
            <p>Dijitall.net {{ authority ? 'Çalışan' : 'Yönetici'}}</p>           
        </div>
        <AvatarImage :size="50" :avatar="$store.getters.user.avatarFile "/>
    </div>
</template>

<script>
import AvatarImage from '@/components/AvatarImage.vue'
    export default {
        name:'UserProfile',
        components: {
            AvatarImage,
        },
    }
</script>

<style lang="scss" scoped>

</style>