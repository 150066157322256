<template>
    <div class="gap-2 items-center w-1/3 flex">
        <b class="w-52">Bugün ne var?</b>
        <el-carousel
            direction="vertical"
            :autoplay="true"
            height="54px" 
            class="w-full"
            v-if="notifyList.length > 0"
        >
            <el-carousel-item class="duyuru flex items-center w-full" v-for="event in notifyList" :key="event.id">
                <p v-if="event.type==1">{{event.user_name}} izinli
                    <sup v-if="event.leave_type==1">Yarım Gün - {{ event.half_type==0 ? 'Öğleden Önce' : 'Öğleden Sonra' }}</sup>
                    <sup v-if="event.leave_type==2">Saatlik - {{ event.hours }} Saat</sup>
                </p>
                <p v-if="event.type==2">{{event.user_name}} raporlu</p>
                <p v-if="event.type==3">{{event.user_name}} uçuşta</p>
                <p v-if="event.type==4">{{event.user_name}} doğum günü!</p>
            </el-carousel-item>
        </el-carousel>
        <div v-else>
            Bugün hiç bir şey yok :/
        </div>
    </div>
</template>

<script>
import { ElCarousel, ElCarouselItem } from 'element-plus';

export default {
    components: {
        ElCarousel,
        ElCarouselItem,
    },
    data() {
        return {
            notifyList: [],
        };
    },
    methods: {
        getCalendar() {
            const date = this.baseDate(new Date())
            try {
                this.$axios.get(`/calendar/whatstoday?start='${date}'`).then((response) => {
                    // Verileri notifyList'e atayın
                    this.notifyList = response.data.data;
                }).catch((error) => {
                    console.error('Takvim verilerini alırken hata oluştu:', error);
                });
            } catch (error) {
                console.error('Takvim verilerini alırken hata oluştu:', error);
            }
        },
    },
    mounted() {
        const self = this;
        self.getCalendar(); 
        setInterval(function() {
            self.getCalendar(); 
        }, 5 * 60 * 1000); 
    },
};
</script>

<style lang="scss" scoped>
</style>
