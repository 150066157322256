// global değişkenler burada yer alır.
import { reactive } from 'vue';

export const veriable = reactive({
    
    calismaTipi:[ //working_type alan adı
        {id:1,name:"Tam Zamanlı"},
        {id:2,name:"Proje Bazlı"},
        {id:3,name:"Freelance"},
        {id:4,name:"Danışman"}
    ],
    kanList:[ //blood_group alan adı
        {id:1,name:"AB Rh+"},
        {id:2,name:"AB Rh-"},
        {id:3,name:"A Rh+"},
        {id:4,name:"A Rh-"},
        {id:5,name:"B Rh+"},
        {id:6,name:"B Rh-"},
        {id:7,name:"O Rh+"},
        {id:8,name:"O Rh-"},
    ],
    mezuniyetList:[ //graduation alan adı
        {id:1,name:"Lise"},
        {id:2,name:"Üniversite (2 Yıllık)"},
        {id:3,name:"Üniversite (4 Yıllık)"}
    ],
    bankaList:[ //bank_name alan adı
        {id:1,name:"İş Bankası"},
        {id:2,name:"Garanti BBVA"},
        {id:3,name:"Finans Bank"},
        {id:4,name:"Vakıf Bank"},
    ],
    ayrilmaList:[ //reason_leaving alan adı
        {id:1,name:"Ücret Yetersizli"},
        {id:2,name:"Şehir Değişimi"}
    ],
    yemekList:[ //food_card alan adı
        {id:1,name:"Mültinet"},
        {id:2,name:"Sodexo"},
        {id:3,name:"SedCart"}
    ],
    eventType:[ // Takvim tip'leri
        {id:1,name:"İzin"},
        {id:2,name:"Raporlu"},
        {id:3,name:"Uçak Modu"}
    ],
    fixtureList:[ // Fixture default demirbaşlar
        {id:1,name:"Laptop"},
        {id:2,name:"Monitor"},
        {id:3,name:"Kasa"},
        {id:4,name:"Superbox"}
    ],
    eventTypeList:[
        {
            id:0,
            type:1,
            leave_type:0,
            name:"Tam Gün",
            total:0,
            icon:'leave-full',
            color:'#FF5B5C'
        },
        {
            id:1,
            type:1,
            leave_type:1,
            name:"Yarım Gün",
            icon:'half-after',
            color:'#5AC6B9'
        },
        {
            id:2,
            type:1,
            leave_type:2,
            name:"Saatlik",
            icon:'leave-clock',
            color:'#EBC95A'
        },
        {
            id:3,
            type:2,
            leave_type:0,
            name:"Raporlu",
            icon:'report',
            color:'#F57E27'
        },
        {
            id:4,
            type:3,
            leave_type:0,
            name:"Uçak Modu",
            icon:'airplane',
            color:'#7691DA'
        },
        {
            id:5,
            type:4,
            leave_type:0,
            name:"Doğum Günü",
            icon:'birthday-cake',
            color:'#E6A6DB'
        }
    ],
});