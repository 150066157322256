import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apiInstance from './axios';

//Eklentiler
import VueTheMask from 'vue-the-mask';

const app = createApp(App);

import '@/assets/css/icon-set.css'
import 'element-plus/dist/index.css'
import '@/assets/css/main.css'

import { ElNotification, ElDatePicker, ElMessage } from 'element-plus'
app.config.globalProperties.$notify = ElNotification;
app.config.globalProperties.$message = ElMessage;

import {veriable} from './utils/veriable';
app.config.globalProperties.$globalVeriable = veriable;

import globalFunc from './utils/globalFunc';
app.mixin(globalFunc);

app.config.globalProperties.$axios = apiInstance;

// ElDatePicker component'ini global olarak tanıtın
app.component(ElDatePicker.name, ElDatePicker);

app.use(store)
app.use(VueTheMask);
app.use(router)
app.mount('#app')
